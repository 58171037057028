import React from 'react'
import * as S from './styled'
import UserImg from './userImg'
import PinkLine from '../shared/PinkLine'
import CardsFaleConosco from './cardsFaleConosco'

const Contact = ({ data }) => {
  const {
    wordpressWpGatsbyPage: {
      acf: { 
        title,
        demonstrative_text: demonstrativeText,
        title_cards_contact_blue,
        title_cards_contact_pink,
        line1_card1_icon,
        line1_card1_txt1,
        line1_card1_txt2,
        line1_card1_txt3,
        line1_card2_icon,
        line1_card2_txt1,
        line1_card2_txt2,
        line1_card2_txt3,
        line1_card3_icon,
        line1_card3_txt1,
        line1_card3_txt2,
        line1_card3_txt3,
        line2_card1_icon,
        line2_card1_txt1,
        line2_card1_txt2,
        line2_card1_txt3,
        line2_card2_icon,
        line2_card2_txt1,
        line2_card2_txt2,
        line2_card2_txt3,
        line2_card3_icon,
        line2_card3_txt1,
        line2_card3_txt2,
        line2_card3_txt3
      },
    },
  } = data

  const cardsResult = {
    line1: {
      title: title_cards_contact_blue,
      data: [
        {
          icon: line1_card1_icon,
          txt1: line1_card1_txt1,
          txt2: line1_card1_txt2,
          txt3: line1_card1_txt3
        },
        {
          icon: line1_card2_icon,
          txt1: line1_card2_txt1,
          txt2: line1_card2_txt2,
          txt3: line1_card2_txt3
        },
        {
          icon: line1_card3_icon,
          txt1: line1_card3_txt1,
          txt2: line1_card3_txt2,
          txt3: line1_card3_txt3
        }
      ]
    },
    line2: {
      title: title_cards_contact_pink,
      data: [
        {
          icon: line2_card1_icon,
          txt1: line2_card1_txt1,
          txt2: line2_card1_txt2,
          txt3: line2_card1_txt3
        },
        {
          icon: line2_card2_icon,
          txt1: line2_card2_txt1,
          txt2: line2_card2_txt2,
          txt3: line2_card2_txt3
        },
        {
          icon: line2_card3_icon,
          txt1: line2_card3_txt1,
          txt2: line2_card3_txt2,
          txt3: line2_card3_txt3
        }
      ]
    }
  }

  return (
    <>
      <S.ContactWrapper>
        <S.ContactContent>

          <S.Header>{title}</S.Header>
          <S.TextContact>{demonstrativeText}</S.TextContact>

          <S.ContactCardsContainer>

            {cardsResult.line1 && (
              <S.ContactCards>
                <CardsFaleConosco 
                  cards={cardsResult.line1.data} 
                  title={cardsResult.line1.title} 
                  line="1" 
                />
              </S.ContactCards>
            )}

            {cardsResult.line2 && (
              <S.ContactCards>
                <CardsFaleConosco 
                  cards={cardsResult.line2.data}
                  title={cardsResult.line2.title} 
                  line="2"
                />
              </S.ContactCards>
            )}
          </S.ContactCardsContainer>
        </S.ContactContent>

        <S.UserImgContainer>
          <UserImg />
        </S.UserImgContainer>
      </S.ContactWrapper>
      <PinkLine />
    </>
  )
}

export default Contact
