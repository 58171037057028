import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Contact from 'components/contact'

const ContatoPage = ({ data, location, pageContext }) => {
  const wordpressData = data.wordpressWpGatsbyPage.acf

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO
        title={wordpressData.title}
        description={wordpressData.subtitle}
        canonical={wordpressData.canonical}
        pathName={location.pathname}
      />
      <Contact data={data} />
    </Layout>
  )
}

ContatoPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default ContatoPage

export const pageQuery = graphql`
  query($slug: String!) {
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        title
        subtitle
        canonical
        demonstrative_text
        title_cards_contact_blue
        line1_card1_icon
        line1_card1_txt1
        line1_card1_txt2
        line1_card1_txt3
        line1_card2_icon
        line1_card2_txt1
        line1_card2_txt2
        line1_card2_txt3
        line1_card3_icon
        line1_card3_txt1
        line1_card3_txt2
        line1_card3_txt3
        title_cards_contact_pink
        line2_card1_icon
        line2_card1_txt1
        line2_card1_txt2
        line2_card1_txt3
        line2_card2_icon
        line2_card2_txt1
        line2_card2_txt2
        line2_card2_txt3
        line2_card3_icon
        line2_card3_txt1
        line2_card3_txt2
        line2_card3_txt3
      }
    }
  }
`
