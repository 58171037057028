import React from 'react'
import * as S from './styled'
import IconWrapper from '../shared/IconWrapper'
import { getAlmaIconByName } from '../shared/getAlmaIconByName'
import { getLinkByIcon } from '../../utils/getLinkByIcon'

const CardsFaleConosco = ({cards, line, title}) => {
    return (
        <>
            {cards.length > 0 && (
                <>
                    <S.ContactCardsTitle>{title}</S.ContactCardsTitle>

                    <S.ContactCardsDiv>
                        {cards.map(card => {
                            if (line === "1") {
                                return (
                                    <S.ContactCardBlue key={card.id}>
                                        <IconWrapper Icon={getAlmaIconByName(card.icon)} size="22" />

                                        <S.ContactCardLink 
                                            href={getLinkByIcon(card.icon, card.txt1)}
                                            target="_blank"
                                            dangerouslySetInnerHTML={{ __html: card.txt1 }} 
                                        />

                                        {card.txt2 !== "-" && (
                                            <S.ContactCardText 
                                            dangerouslySetInnerHTML={{ __html: card.txt2 }} 
                                            />
                                        )}

                                        {card.txt3 !== "-" && (
                                            <S.ContactCardText
                                            dangerouslySetInnerHTML={{ __html: card.txt3 }} 
                                            />
                                        )}
                                    </S.ContactCardBlue>
                                )
                            } else {
                                return (
                                    <S.ContactCardPink key={card.id}>
                                        
                                    <IconWrapper Icon={getAlmaIconByName(card.icon)} size="22" />

                                    <S.ContactCardLink 
                                        href={getLinkByIcon(card.icon, card.txt1)}
                                        target="_blank"
                                        dangerouslySetInnerHTML={{ __html: card.txt1 }} 
                                    />

                                    {card.txt2 !== "-" && (
                                        <S.ContactCardText 
                                        dangerouslySetInnerHTML={{ __html: card.txt2 }} 
                                        />
                                    )}
                                    {card.txt3 !== "-" && (
                                        <S.ContactCardText
                                        dangerouslySetInnerHTML={{ __html: card.txt3 }} 
                                        />
                                    )}
                                    </S.ContactCardPink>
                                )
                            }
                        })}
                    </S.ContactCardsDiv>
                </>
            )}
        </>
    )
}

export default CardsFaleConosco
